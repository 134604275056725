<template>
  <div class="page mobile">
    <div class="banner">
      <!-- 轮播图 -->
      <aspect-ratio class="banner-carousel" ratio="45.75">
        <!-- 轮播图尺寸 1366*625 宽高比 0.4575 -->
        <el-carousel trigger="click" :autoplay="true" arrow="always">
          <el-carousel-item v-for="banner in bannerImages" v-bind:key="banner.bannerId">
            <div class="banner-item" @click="goToBannerText(banner)">
              <aspect-ratio ratio="45.75">
                <img v-bind:src="$resources.autoTransform(banner.imgUrl)"/>
              </aspect-ratio>
            </div>
          </el-carousel-item>
        </el-carousel>

      </aspect-ratio>

      <!-- 菜单 -->
      <div class="banner-menu">
        <ul class="menus reset">
          <li class="menu">
            <div class="menu-header" @click="toggleMenu('productCenter')">
              {{$t('layout.productsCenter')}}
            </div>
            <el-collapse-transition>
              <ul v-show="visibleMenuName === 'productCenter'" class="sub-menus hide reset">
                <li v-for="p in headerItems" v-bind:key="p.itemId" class="sub-menu">
                  <router-link :to="`/product/product-detail?productId=${p.itemId}&product=${p.name}`">
                    {{$i18n.locale === 'zh-CN' ? p.zhName : p.enName}}
                  </router-link>
                </li>
                <li class="sub-menu more">
                  <router-link to="/product/product-center">
                    {{$t('layout.viewMore')}}
                  </router-link>
                </li>
              </ul>
            </el-collapse-transition>
          </li>
          <li class="menu">
            <div class="menu-header" @click="toggleMenu('aboutUs')">
              {{$t('layout.aboutUs')}}
            </div>
            <el-collapse-transition>
              <ul v-show="visibleMenuName === 'aboutUs'" class="sub-menus reset">
                <li class="sub-menu">
                  <router-link to="/company/profile">{{$t('layout.companyProfile')}}</router-link>
                </li>
                <li class="sub-menu">
                  <router-link to="/company/culture">{{$t('layout.companyCulture')}}</router-link>
                </li>
              </ul>
            </el-collapse-transition>
          </li>
          <li class="menu">
            <div class="menu-header" @click="toggleMenu('photos')">{{$t('layout.companyPhotos')}}</div>
            <el-collapse-transition>
              <ul v-show="visibleMenuName === 'photos'" class="sub-menus reset">
                <li class="sub-menu">
                  <router-link :to="'/photos/photos?type=' + $t('layout.companyHonor')">
                    {{$t('layout.companyHonor')}}
                  </router-link>
                </li>
                <li class="sub-menu">
                  <router-link :to="'/photos/photos?type=' + $t('layout.companyEnvironment')">
                    {{$t('layout.companyEnvironment')}}
                  </router-link>
                </li>
              </ul>
            </el-collapse-transition>
          </li>
          <li class="menu">
            <router-link tag="div" class="menu-header no-sub" :to="'/contact-us'">{{$t('layout.contactUs')}}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <!-- 视频 -->
    <div class="video">
      <!-- 视频保持1288*646 宽高比50.16 -->
      <aspect-ratio ratio="50.16">
        <div v-if="!showVideo" class="video-cover" @click="showVideo = true">
            <img v-bind:src="$resources.autoTransform($resources.Image.static['u171.jpg'])" />
        </div>
        <div v-else class="video-media">
          <video v-bind:src="$resources.Video.static['u171.mp4']" controls autoplay style="width:100%; height: 100%;">
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </aspect-ratio>
    </div>

    <!-- 公司简介、企业文化 -->
    <div class="profiles">
      <!-- 轮播保持708*237 宽高比 -->
      <!--<aspect-ratio ratio="32.47">-->
      <aspect-ratio ratio="40">
        <el-carousel ref="profileCarousel" :autoplay="false" arrow="always" indicator-position="none">
          <el-carousel-item>
            <router-link to="/company/profile" class="pointer flex flex-sub align-center">
              <div class="image">
                  <img v-bind:src="$resources.autoTransform($resources.Image.static['u169.jpg'])" />
              </div>
              <div class="desc">
                <span class="title">{{$t('layout.companyFullName')}}</span>
                <span class="text">{{$t('home.companyAbout')}}</span>
                <router-link to="/company/profile" class="more">more</router-link>
              </div>
            </router-link>
          </el-carousel-item>
          <el-carousel-item>
            <router-link to="/company/culture" class="pointer flex flex-sub align-center">
              <div class="image">
                  <img v-bind:src="$resources.autoTransform($resources.Image.static['u775.jpg'])" />
              </div>
              <div class="desc">
                <span class="title">{{$t('layout.companyFullName')}}</span>
                <span class="text">{{$t('home.companyCulture')}}</span>
                <router-link to="/company/culture" class="more">more</router-link>
              </div>
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </aspect-ratio>
      <!-- 切换上一个、下一个 -->
      <div class="profiles-prev" @click="$refs.profileCarousel.prev()">
        <i class="icon-arrow-left"></i>
      </div>
      <div class="profiles-next" @click="$refs.profileCarousel.next()">
        <i class="icon-arrow-right"></i>
      </div>
      <!-- 指示器 -->
      <div class="profiles-indicators">
        <div :class="'indicator ' + ($refs.profileCarousel && $refs.profileCarousel.activeIndex === 0 ? 'is-active' : '')"
             @mouseenter="$refs.profileCarousel.setActiveItem(0)"></div>
        <div :class="'indicator ' + ($refs.profileCarousel && $refs.profileCarousel.activeIndex === 1 ? 'is-active' : '')"
             @mouseenter="$refs.profileCarousel.setActiveItem(1)"></div>
      </div>
    </div>

    <!-- 新闻资讯 -->
    <div class="news">
      <div class="title">{{$t('layout.news')}}</div>
      <div class="content">
        <router-link :to="`/news/news-detail?newsId=${coverNews.newsId}`" class="preview-news">
          <!-- 新闻图片400*270 -->
          <aspect-ratio class="preview-news-image" ratio="67.5">
            <img v-bind:src="$resources.autoTransform(coverNews.coverImgUrl)"/>
          </aspect-ratio>
          <div class="preview-news-info">
            <span class="news-time">
                {{coverNews.type === '公司要闻' ? $t('layout.companyNews') : $t('layout.industryNews')}} | {{coverNews.gmtCreated}}
            </span>
            <span class="news-title">
                {{$i18n.locale === 'zh-CN' ? coverNews.zhTitle : coverNews.enTitle}}
            </span>
            <span class="more"> Detail</span>
          </div>
        </router-link>
        <div class="news-list">
          <router-link v-for="n in news" v-bind:key="n.newsId" class="news-item"
                       :to="`/news/news-detail?newsId=${n.newsId}`">
            <span class="news-time">
                {{n.type === '公司要闻' ? $t('layout.companyNews') : $t('layout.industryNews')}} | {{n.gmtCreated}}
            </span>
            <span class="news-title">
                {{$i18n.locale === 'zh-CN' ? n.zhTitle : n.enTitle}}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AspectRatio from '../../components/AspectRatio';

  export default {

    components: {
      'aspect-ratio': AspectRatio,
    },

    data() {
      return {

        showVideo: false,

        items: [],

        visibleMenuName: '',
        bannerImages: [],

        coverNews: {},
        news: [],
      };
    },

    computed: {
      headerItems() {
        if (!this.items || this.items.length <= 0) {
          return [];
        } else {
          this.items.forEach(i => {
            i.name = this.$i18n.locale === 'zh-CN' ? i.zhName : i.enName;
          });
          return this.items;
        }
      },
    },

    created() {
      window.page = this;

      this.loadBanner();
      this.loadNews();
      this.$cache.get('layout.items').then(items => {
        this.items = items;
      });
    },

    methods: {
      toggleMenu(menuName) {
        if (this.visibleMenuName === menuName) {
          this.visibleMenuName = '';
        } else {
          this.visibleMenuName = menuName;
        }
      },

      loadBanner() {
        this.$ajax.get('/carousel/items').then(response => {
          const banners = response.data;
          if (banners && banners.length > 0) {
            this.bannerImages = banners;
          } else {
            console.warn('Banner未设置！');
          }
        });
      },

      goToBannerText(banner) {
        if (!banner || !banner.isRoute) return;
        const title = this.$i18n.locale === 'zh-CN' ? banner.zhTitle : banner.enTitle;
        if (!title || title === '') {
          console.warn('banner不存在内容信息');
        } else {
          this.$router.push({
            path: '/article',
            query: {
              title,
              articleId: banner.bannerId,
              lang: this.$i18n.locale
            }
          });
        }
      },

      loadNews() {
        this.$ajax.get('/news/home').then(response => {
          const news = response.data;
          if (news && news.length > 0) {
            let showNews = [];
            news.forEach(n => {
              this.formatNewsMessage(n);
              if (n.isMain) this.coverNews = n;
              else showNews.push(n);
            });
            this.news = showNews;
          }
        });
      },

      formatNewsMessage(news) {
        const year = news.gmtCreated.substr(0, 4);
        const month = news.gmtCreated.substr(5, 2);
        const day = news.gmtCreated.substr(8, 2);
        news.gmtCreated = `${year}年${parseInt(month)}月${parseInt(day)}日`;
      },
    }
  }
</script>

<style lang="scss">

  .page.mobile {
    background-color: var(--white);

    .banner {
      width: 100%;

      .banner-carousel {
        width: 100%;

        .el-carousel {
          width: 100%;
          height: 100% !important;
          position: absolute;
          top: 0;
          left: 0;

          .el-carousel__container {
            height: 100%;
          }
        }

      }

      .banner-menu {
        .menus {
          width: 100%;

          .menu {
            &:not(:first-child) {
              border-top: 1px solid #f0f0f0;
            }

            .menu-header {
              padding: 0 20px;
              font-size: 16px;
              font-weight: 700;
              line-height: 50px;
              position: relative;
              &:after {
                content: "\e84b";
                font-family: iconfont, serif;
                position: absolute;
                right: 20px;
              }
              &.no-sub:after {
                content: "";
              }
            }

            .sub-menus {
              overflow: hidden;
              border: 1px solid #f0f0f0;

              .sub-menu {
                padding: 0 20px;
                font-size: 16px;
                line-height: 40px;
                &:first-child {
                  margin-top: 10px;
                }
                &:last-child {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }

    .video {
      .video-cover {
        width: 100%;
        height: 100%;
      }

      .video-media {
        width: 100%;
        height: 100%;

        video:focus {
          outline: none;
        }
      }
    }

    .profiles {
      width: 100%;
      margin: 20px 0;
      padding: 0 10px;
      position: relative;

      .el-carousel {
        height: 100% !important;

        .el-carousel__container {
          height: 100%;

          .el-carousel__item {
            display: flex;

              .pointer {
                  @media(min-width: 760px) {
                      padding: 0px 10px;
                  }

              }

            .image {
              flex: 1;
              height: 100%;
              img {
                border-radius: 5px;
              }
            }
            .desc {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: start;
              flex: 1;
              padding: 10px 5px;
              background-color: rgba(204, 204, 255, 1);
              border-radius: 0 6px 6px 0;

                @media(min-width: 760px) {
                    padding: 50px 0px 50px 20px;
                }

              .title {
                font-size: 15px;
                font-weight: 400;

                  @media(min-width: 760px) {
                      font-size: 18px;
                  }
              }
              .text {
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;

                  @media(min-width: 760px) {
                      font-size: 20px;
                      line-height: 32px;
                      padding-bottom: 32px;
                  }
              }
              .more {
                margin-top: 10px;
                padding: 0 10px;
                color: var(--black);
                font-size: 15px;
                line-height: 24px;
                border: 1px solid var(--black);
                border-radius: 5px;
              }
            }
          }
        }
      }

      .profiles-prev, .profiles-next {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 20px;
        transform: translateY(-50%);
        cursor: pointer;
        position: absolute;
        top: 50%;
      }

      .profiles-prev {
        left: 0;
      }

      .profiles-next {
        right: 0;
      }

      .profiles-indicators {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .indicator {
          width: 10px;
          height: 10px;
          margin: 0 3px;
          border: 1px solid var(--grey);
          border-radius: 50%;
          background-color: white;
          opacity: 1;
          cursor: pointer;

          &.is-active {
            background-color: var(--grey);
          }
        }
      }
    }

    .news {
      width: 100%;
      padding-bottom: 20px;
      color: var(--black);

      .title {
        padding-left: 20px;
        font-size: 20px;
        font-weight: 700;
      }

      .content {
        margin-top: 20px;

        .preview-news {
          img {
            border-radius: 5px 5px 0 0;
          }

          .preview-news-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 15px;
            color: var(--black);
            font-size: 14px;
            line-height: 24px;
            background-color: #ccccff;
            border-radius: 0 0 5px 5px;

            .news-title {
              font-weight: 700;
            }

            .more {
              margin-top: 10px;
              padding: 0 10px;
              font-size: 14px;
              line-height: 24px;
              border: 1px solid var(--black);
              border-radius: 5px;
            }
          }
        }

        .news-list {
          flex: 1;
          max-width: 600px;
          padding: 0 10px;
          color: var(--black);

          .news-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;
            cursor: pointer;

            .news-time {
              font-size: 16px;
              line-height: 50px;
              position: relative;
            }
            .news-time:after {
              content: '';
              display: block;
              width: 60%;
              height: 3px;
              background-color: #d3d3d3;
              position: absolute;
              bottom: 5px;
            }

            .news-title {
              font-size: 16px;
              font-weight: 500;
              text-align: justify;
            }
          }

          .news-item:first-child {
            margin-top: 10px;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
